import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import FanSpeedComponent from "../dialog-selectors/FanSpeedComponent";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { ReactNode } from "react";
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd, CmdSendGeneralCmdWrite } from "../../types/message";

type FanModeValue = typeof Constants.FanModes[number]["value"];

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF99">;
	showFull?: boolean;
}>;

const FAN_SPEED_MIN = 1;
const FAN_SPEED_MAX = 24;

const DFF99 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const fanModes = (typeof cluster?.[ClusterConstants.DFF99.Attributes.FanModeSupport] === "number") ? Constants.FanModes.filter((fanMode) => (Boolean(cluster[ClusterConstants.DFF99.Attributes.FanModeSupport] & fanMode.bitFlag))) : Constants.FanModes;
	const fanSpeedMax = cluster?.[ClusterConstants.DFF99.Attributes.FanMaxSpeed] ?? FAN_SPEED_MAX;
	const isFanDirectionSupported = cluster?.[ClusterConstants.DFF99.Attributes.FanDirectionSupport] ?? true;

	const [showModesDialog, setShowModesDialog] = useState(false);
	const [showFanSpeedDialog, setShowFanSpeedDialog] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const [fanModeValue, setFanModeValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF99.Attributes.FanMode] ?? null);
	const [horizontalSwingValue, setHorizontalSwingValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF99.Attributes.HorizontalSwing] ?? null);
	const [fanSpeedValue, setFanSpeedValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF99.Attributes.FanSpeed] ?? null);
	const [verticalSwingValue, setVerticalSwingValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF99.Attributes.VerticalSwing] ?? null);
	const [fanDirectionValue, setFanDirectionValue] = useDynamicUpdateState((typeof cluster?.[ClusterConstants.DFF99.Attributes.FanDirection] === "number") ? Boolean(cluster[ClusterConstants.DFF99.Attributes.FanDirection]) : null);

	const setFanSpeed = (fanSpeed: number) => {
		if (fanSpeedValue !== fanSpeed) {
			setFanSpeedValue(fanSpeed);

			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.DFF99.Attributes.FanSpeed,
					datatype: Constants.DataType.UInt8Bit,
					value: decimal2Hex(fanSpeed, 2),
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"FF99">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
			});
		}
	};

	const handleFanSpeedChange = (fanSpeed: number) => {
		setShowFanSpeedDialog(false);
		setFanSpeed(fanSpeed);
	};

	const handleInDeCreaseFanSpeedClick = (isIncrement: boolean) => {
		const newFanSpeedValue = isIncrement ? Number(fanSpeedValue) + 1 : Number(fanSpeedValue) - 1;
		setFanSpeed(newFanSpeedValue);
	};

	const handleModeClick = (fanMode: FanModeValue) => {
		setShowModesDialog(false);
		if (fanModeValue !== fanMode) {
			setFanModeValue(fanMode);

			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.DFF99.CmdIds.Mode,
				value: decimal2Hex(fanMode, 2),
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
			});
		}
	};

	const handleSwingToggle = (isInputChecked: boolean, isHorizontal: boolean) => {
		if (isHorizontal) {
			setHorizontalSwingValue(isInputChecked);
		} else {
			setVerticalSwingValue(isInputChecked);
		}

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: isHorizontal ? ClusterConstants.DFF99.CmdIds.HorizontalSwing : ClusterConstants.DFF99.CmdIds.VerticalSwing,
			value: isInputChecked ? ClusterConstants.DFF99.CmdPayloads.SwingOn : ClusterConstants.DFF99.CmdPayloads.SwingOff,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleDirectionToggle = (isInputChecked: boolean) => {
		setFanDirectionValue(isInputChecked);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFF99.CmdIds.FanDirection,
			value: isInputChecked ? ClusterConstants.DFF99.FanDirectionCmdPayloads.Reverse : ClusterConstants.DFF99.FanDirectionCmdPayloads.Forward,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		const entries: Array<ReactNode> = [];

		if (fanSpeedValue !== null) {
			entries.push(
				<>
					<ListItem sx={{ padding: "0 4px 0 16px" }}>
						<ListItemText primary={t("clusters.DFF99.titleFanSpeed")} />
						<ListItemContainer style={{ display: "flex", alignItems: "center" }}>
							<IconButton
								color="primary"
								disabled={fanSpeedValue === FAN_SPEED_MIN}
								onClick={() => (handleInDeCreaseFanSpeedClick(false))}
							>
								<icons.RemoveCircle />
							</IconButton>
							<Button onClick={() => (setShowFanSpeedDialog(true))} sx={{ minWidth: 30 }}>
								{fanSpeedValue}
							</Button>
							<IconButton
								color="primary"
								disabled={fanSpeedValue === fanSpeedMax}
								onClick={() => (handleInDeCreaseFanSpeedClick(true))}
							>
								<icons.AddCircle />
							</IconButton>
						</ListItemContainer>
					</ListItem>
					<FanSpeedComponent
						open={showFanSpeedDialog}
						value={fanSpeedValue}
						maxSpeed={fanSpeedMax}
						onChange={handleFanSpeedChange}
						onCancel={() => (setShowFanSpeedDialog(false))}
					/>
				</>
			);
		}
		if (isFanDirectionSupported && fanDirectionValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF99.winterMode")} />
					<ListItemContainer>
						<CustomSwitch
							checked={fanDirectionValue}
							onChange={handleDirectionToggle}
						/>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (fanModeValue !== null && fanModes.length > 0) {
			const fanMode = fanModes.find((fanMode) => (fanMode.value === fanModeValue));
			entries.push(
				<>
					<ListItemButton onClick={() => (setShowModesDialog(true))}>
						<ListItemText primary={t("clusters.DFF99.titleMode")} />
						<ListItemText
							primary={fanMode ? t(fanMode.l10n) : ""}
							slotProps={{ primary: { sx: { color: "primary.main" } }}}
						/>
					</ListItemButton>
					<DrawerDialog
						id="dlg-fan-mode"
						title={t("clusters.DFF99.dialogTitle")}
						open={showModesDialog}
						onClose={() => (setShowModesDialog(false))}
						drawerActions={null}
						dialogActions={<Button className="btn-dlg-action-cancel" color="inherit" onClick={() => (setShowModesDialog(false))}>{t("dialog.cancel")}</Button>}
					>
						<List disablePadding={true}>
							{fanModes.map((mode) => (
								<ListItemButton
									className="li-fan-mode"
									data-fan-mode={mode.value}
									key={mode.value}
									sx={{ color: (mode.value === fanModeValue) ? "primary.main" : undefined }}
									onClick={() => (handleModeClick(mode.value))}
								>
									<ListItemText primary={t(mode.l10n)} />
								</ListItemButton>
							))}
						</List>
					</DrawerDialog>
				</>
			);
		}
		if (horizontalSwingValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF99.horizontalSwing")} />
					<ListItemContainer>
						<CustomSwitch
							checked={horizontalSwingValue}
							onChange={(isInputChecked) => (handleSwingToggle(isInputChecked, true))}
						/>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (verticalSwingValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF99.verticalSwing")} />
					<ListItemContainer>
						<CustomSwitch
							checked={verticalSwingValue}
							onChange={(isInputChecked) => (handleSwingToggle(isInputChecked, false))}
							vertical={true}
						/>
					</ListItemContainer>
				</ListItem>
			);
		}

		return (
			<>
				{entries.map((entry, index) => (
					<Fragment key={index}>
						{(index > 0) && <Divider />}
						{entry}
					</Fragment>
				))}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

DFF99.defaultProps = {
	showFull: false,
};

export default DFF99;
